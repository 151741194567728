import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { homepage } from "../utils/variables";
import PageMetaTags from "../components/PageMetaTags";

const Homepage = ({ posts, setPosts, currentPage, setCurrentPage }) => {
  const [excerptOpen, setExcerptOpen] = useState(null);

  useEffect(() => {
    setCurrentPage(homepage);
  }, []);

  const params = new URLSearchParams(window.location.search);
  const postNotFound = params.get("404");
  const homeText = postNotFound
    ? "sorry the post you're looking for doesn't exist, try one of these instead"
    : "a very good blog";

  return (
    <div className="homepage-container">
      <div className="texture-container"></div>
      <div
        className={`post-loading-container${
          posts.length > 0 ? " done-loading" : ""
        }`}
      >
        <h1
          className={`post-loading-text${
            posts.length > 0 ? " text-done-loading" : ""
          }`}
        >
          FETCHING THE POSTS
        </h1>
      </div>

      <PageMetaTags
        title={"Moon 21"}
        description="A very good blog about vast number of things."
      />

      <h3 className="posts-desc">{homeText}</h3>
      <h2 className="posts-label">the posts</h2>
      <div className="posts-container">
        {posts.map((post) => (
          <div key={post.slug + post.id} className="post">
            <Link to={"/post/" + post.slug}>
              <h3 className="post-title">{post.title}</h3>
            </Link>
            {post.excerpt && (
              <p
                onClick={() => setExcerptOpen(post.excerpt)}
                className="post-excerpt-link"
              >
                excerpt
              </p>
            )}

            {excerptOpen && (
              <div
                onClick={() => setExcerptOpen(null)}
                className="excerpt-container"
              >
                <h2 className="excerpt-heading">excerpt</h2>
                <h2 className="excerpt">{excerptOpen}</h2>

                <div className="close-excerpt-btn-container">
                  <div className="cross-tick1 close-excerpt-btn"></div>
                  <div className="cross-tick2 close-excerpt-btn"></div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
