import React from "react";
import { siteName } from "../utils/variables";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-text">{siteName}</div>
    </div>
  );
};

export default Footer;
