import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import PostDetail from "./pages/PostDetail";
import { homepage, postsBaseUrl } from "./utils/variables";
import bgImg from "./assets/tex.gif";

const App = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(homepage);

  const getAndSetPosts = () => {
    fetch("/posts.json")
      .then((res) => res.json().then((postsData) => setPosts(postsData.posts)))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAndSetPosts();
  }, []);

  return (
    <div className="app-container">
      <img src={bgImg} alt="" className="overlay" />
      <Router>
        <Header currentPage={currentPage} />
        <Switch>
          <Route
            exact
            path="/post/:slug"
            render={() => (
              <PostDetail posts={posts} setCurrentPage={setCurrentPage} />
            )}
          />

          <Route
            path=""
            render={() => (
              <Homepage
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                posts={posts}
                setPosts={setPosts}
              />
            )}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
