import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const PageMetaTags = ({ title, description, colour }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* <meta name="theme-color" content={colour ? colour : "#fff"} /> */}
    </Helmet>
  );
};

export default PageMetaTags;
