import React, { useEffect, useState } from "react";
import sunImg from "../assets/sun.gif";
import { postDetail } from "../utils/variables";
import { Link } from "react-router-dom";

const Header = ({ currentPage }) => {
  const [darkModeOn, setDarkModeOn] = useState(false);

  const dynamicElementsStyle =
    currentPage === postDetail ? { opacity: 0, pointerEvents: "none" } : {};

  const html = document.querySelector("html");
  const darkMode = "dark-mode";
  const containerStyles = currentPage === postDetail ? { width: "80px" } : {};

  const circleRadius = 25;

  const modeMaskStyle = darkModeOn
    ? {
        left: 20,
        transition: "all 500ms ease-out",
      }
    : { transition: "all 500ms ease-out" };

  useEffect(() => {
    setDarkModeOn(html.classList.contains(darkMode));
  }, []);

  const handleModeChange = () => {
    html.classList.toggle(darkMode);
    setDarkModeOn(html.classList.contains(darkMode));
  };

  return (
    <div style={containerStyles} className="header-container">
      <div style={dynamicElementsStyle} className="header-container-bg"></div>
      <Link to="/">
        <img src={sunImg} alt="" className="logo" />
      </Link>

      <div style={dynamicElementsStyle} className="header-right-container">
        <h3 className="message-text">愛、いつも</h3>
        <div onClick={handleModeChange} className="mode-toggle">
          <svg
            className="mode-icon"
            viewBox={`0 0 ${circleRadius * 2} ${circleRadius * 2}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx={circleRadius} cy={circleRadius} r={circleRadius} />
          </svg>
          <div style={modeMaskStyle} className="mode-mask-container">
            <svg
              className="mode-mask"
              viewBox={`0 0 ${circleRadius * 2} ${circleRadius * 2}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={circleRadius} cy={circleRadius} r={circleRadius} />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
