import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { postDetail, postsBaseUrl, siteName } from "../utils/variables";
import PageMetaTags from "../components/PageMetaTags";

const PostDetail = ({ posts, setCurrentPage }) => {
  const [post, setPost] = useState("");
  const [doneLoading, setDoneLoading] = useState(false);
  const [toRedirect, setToRedirect] = useState(false);

  useEffect(() => {
    setCurrentPage(postDetail);
    getPost();
  }, []);

  const pathArray = window.location.pathname.split("/");
  const postSlug = pathArray.slice(pathArray.length - 1, pathArray.length)[0];

  const redirectHome = () => setToRedirect(true);

  const getFetchedPost = (postsArray) =>
    postsArray.filter(
      (postObj) => postObj.slug.toLowerCase() === postSlug.toLowerCase()
    )[0];

  const getAndSetPost = () => {
    fetch("/posts.json")
      .then((res) =>
        res.json().then(({ posts }) => {
          const fetchedPost = getFetchedPost(posts);
          if (fetchedPost) setPost(fetchedPost);
          else redirectHome();
        })
      )
      .catch((err) => {
        redirectHome();
      });
  };

  const getPost = () => {
    if (posts.length > 0) {
      const fetchedPost = getFetchedPost(posts);
      if (fetchedPost) setPost(fetchedPost);
      else redirectHome();
      return;
    }
    getAndSetPost();
  };

  if (toRedirect) return <Redirect to="/?404=true" />;

  return (
    <div style={{ background: "#000" }} className="post-detail-container">
      <div
        className={`post-loading-container${
          doneLoading ? " done-loading" : ""
        }`}
      >
        <h1
          className={`post-loading-text${
            doneLoading ? " text-done-loading" : ""
          }`}
        >
          FETCHING THE POST
        </h1>
      </div>

      {post ? (
        <PageMetaTags
          title={post.title + " | " + siteName}
          colour={post.colour}
          description={post.excerpt}
        />
      ) : (
        <PageMetaTags
          title={siteName + " Posts"}
          description="Really good blog posts"
        />
      )}

      {post && (
        <iframe
          src={"https://posts.moon21.co.za/" + postSlug}
          frameBorder="0"
          height={window.innerHeight}
          width={window.innerWidth}
          onLoad={() => setDoneLoading(true)}
        ></iframe>
      )}
    </div>
  );
};

export default PostDetail;
