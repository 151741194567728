export const siteName = "Moon21";

const developmentMode = true;

export const postsBaseUrl = developmentMode
  ? "http://localhost:8000"
  : "deployedServerUrl";

export const homepage = "homepage";
export const postDetail = "post-detail";
export const homepageRoute = "";
export const postDetailRoute = "post/:slug";
